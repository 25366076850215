@import "~@/styles/variables";






































.search-clear {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
