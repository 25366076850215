@import "~@/styles/variables";
































































































































@media (max-width: map-get($grid-breakpoints, 'sm')) {
  .actions {
    flex-direction: column-reverse;

    .submit-btn {
      width: 100%;
    }
  }
}
