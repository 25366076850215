@import "~@/styles/variables";


































































.offline-instructions {
  font-size: $f-size-rg;
}

.bullet-list {
  li {
    margin-bottom: $spacer;
  }
}
