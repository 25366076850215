@import "~@/styles/variables";
























































.care-user {
  min-width: 15em;
  display: flex;
  align-items: center;
}
