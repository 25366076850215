@import "~@/styles/variables";


















































$hover-color: $pp-border-blue;

.swatch {
  width: 3em;
  height: 3em;
  border-radius: 50%;
  cursor: pointer;
  transition: $transition-base;
}

.custom-control-input {
  &:focus + label .swatch,
  &:hover + label .swatch {
    box-shadow: 0 0 0 0 transparent, 0 0 0 5px $hover-color;
  }
  &:active + label .swatch,
  &:checked + label .swatch {
    box-shadow: 0 0 0 4px $pp-white, 0 0 0 5px $pp-ocean;
  }
}
