@import "~@/styles/variables";

















.skip-to-main:focus {
  position: fixed;
  left: 0;
  top: 0;
  padding: 1em;
  background: $pp-white;
  z-index: $z-index-10;
  font-weight: bold;
  text-decoration: underline;
}
