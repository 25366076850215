@import "~@/styles/variables";































@page {
  margin: 15in;
}

@media print {
  body,
  html {
    background: $pp-white !important;
  }

  body.print-layout {
    font-size: 18px;
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }

  .screen-only {
    display: none !important;
  }

  .page {
    max-width: none !important;
  }

  .footer-spacer {
    height: 50px;
  }

  .print-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}

@media screen {
  .page {
    min-width: 8.5in;
    min-height: 11in;
    background: $pp-white;
    margin: 0 auto 5em;
    padding: 0.5in !important;
    box-shadow: $box-shadow;
  }

  .print-only {
    display: none !important;
  }

  .print-footer {
    padding-top: 0.5em;
  }

  body.print-layout {
    font-size: 16px;
    margin-bottom: 100px;
  }
}
.print-button {
  position: sticky;
  top: 2em;
  width: 25vw;
  max-width: 10em;
  z-index: $z-index-1;
}
