.med-status {
  &--default {
    color: $pp-dark-grey;
  }

  &--paused {
    color: $pp-orange;
  }

  &--in-progress {
    color: $pp-red;
  }

  &--denied {
    color: $pp-red;
  }

  &--failed {
    color: $pp-red;
  }

  &--shipping {
    color: $pp-green;
  }
}
