// fade
.fade-enter-active, .fade-leave-active {
  transition: opacity .25s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

// fade-up
.fade-up-enter-active, .fade-up-leave-active {
  transition: opacity .25s, transform .25s;
}
.fade-up-enter, .fade-up-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.fade-up-leave-to {
  transform: translateY(-10px);
}

// slide-fade
.slide-fade-enter-active {
  transition: all .25s ease;
}
.slide-fade-leave-active {
  transition: all .25s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(10px);
}

.slide-fade-move {
  transition: transform .25s;
}


.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-complete-leave-active {
  position: absolute;
}