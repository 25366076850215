@import "~@/styles/variables";

































































































































































.dispenser-card {
  position: relative;
}

.dispenser-heading {
  display: block;
  padding-top: 3px;
  font-size: 1.25rem;
}

.dispenser-body {
  z-index: 1;
}

.dispenser-image {
  position: absolute;
  width: 140px;
  right: 0;
  bottom: 0;
}

.modal-dispenser-image {
  width: 300px;
  margin: auto;
}
