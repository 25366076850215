@import "~@/styles/variables";



















@import '~bootstrap/scss/functions';

.step-node {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  line-height: 22px;
  font-size: $f-size-sm;
  border: 1px solid transparent;
}

.past,
.future {
  background: $step-progress-inactive;
}

.current {
  background: $step-progress-active;
}
