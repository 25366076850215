@import "~@/styles/variables";












































































.items {
  display: flex;
  flex-direction: column;
  width: 100%;

  .item {
    display: flex;
    flex-direction: row;
    min-height: 100px;
    padding: 0.2rem 0;

    .item-image-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex-basis: 15%;

      svg {
        height: 100px;
      }

      > .item-image-line {
        border-left: 1px solid $pp-md-grey;
        height: 100%;
        margin: 0.5rem 0;
        width: 1px;
      }
    }

    .item-content {
      flex-basis: 85%;
      padding: 0 1rem;
      text-align: left;
    }

    &:last-child {
      min-height: auto;
      .item-image-line {
        border-left: 0 solid $pp-md-grey;
      }
    }
  }
}
.close-button {
  min-width: 200px;
}
