@import "~@/styles/variables";



















































.radio-group-option {
  border-radius: 0;
  border: 0 solid $custom-select-border-color;
  letter-spacing: normal;
  font-size: larger;
}

.radio-group-icon {
  display: block;
  height: 30px;
}

.radio-group-subtitle {
  display: block;
  font-size: smaller;
  color: $pp-blue-grey;
}
