@import "~@/styles/variables";














.page-subtitle {
  display: block;
  font-size: 1.5rem;
  font-weight: $f-weight-book;
  font-family: $flama;
  margin-bottom: 0.2em;
  color: $pp-ocean;
}
