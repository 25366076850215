@import "~@/styles/variables";


































.horizontal {
  display: flex;

  .icon {
    margin-right: $spacer;
  }
}

.vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .icon {
    margin-bottom: $spacer;
  }
}
