@import "~@/styles/variables";




































































.page-header-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  padding: 0.5em;
  align-self: baseline;
  background: $pp-white;
  color: $pp-primary-blue;
  border-radius: 50%;
  flex-shrink: 0;

  svg,
  img {
    width: 100%;
    height: auto;
  }
}
