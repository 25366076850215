@import "~@/styles/variables";
























































































































































.account-item {
  min-width: 15em;
  display: flex;
  align-items: center;
  padding: 0.5em 0;
}

.link-arrow.down {
  position: relative;
  top: -2px;
}
