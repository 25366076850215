@import "~@/styles/variables";



































@import '~bootstrap/scss/functions';

.step-node {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  line-height: 22px;
  font-size: $f-size-sm;
  border: 1px solid transparent;
}

.future {
  border-color: $step-progress-inactive;
  border-style: dashed;
}

.past,
.current {
  color: $white;
  background: $step-progress-active;
}

.error {
  $background: #fdeae8;
  color: $danger;
  background: $background;
  font-weight: bold;
}
