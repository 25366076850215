// smooth fonts (only works on mac but should degrade gracefully)
body {
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  // Spacing from header
  padding-top: 0.5em;

  // used for pushing the footer below the fold in short pages
  $approximate-nav-height: 100px;
  $margin-bottom: 3em;
  margin-bottom: $margin-bottom;
  flex-grow: 1;
  min-height: calc(100vh - #{$approximate-nav-height} - #{$margin-bottom});
}

@supports(--modern-browser: true) {
  svg {
    height: auto;
  }
}
