@import "~@/styles/variables";



































.insurance-item p {
  margin-bottom: 0.25rem;
}

.shield-wrapper {
  padding: 0px 14px 4px;
  background-color: $pp-snowball;
}
