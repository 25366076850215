// Load Flama and Tiempos fonts
// Don't define styles in this file, use either _typography or _utilities

$font-path: 'https://type.pillpack.com/';

@font-face {
  font-family: 'Flama';
  src: url($font-path+'flama/Flama-Book.woff2') format('woff2'),
    url($font-path+'flama/Flama-Book.woff') format('woff'),
    url($font-path+'flama/Flama-Book.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Flama';
  src: url($font-path+'flama/Flama-BookItalic.woff2') format('woff2'),
    url($font-path+'flama/Flama-BookItalic.woff') format('woff'),
    url($font-path+'flama/Flama-BookItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Flama';
  src: url($font-path+'flama/Flama-Basic.woff2') format('woff2'),
    url($font-path+'flama/Flama-Basic.woff') format('woff'),
    url($font-path+'flama/Flama-Basic.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Flama';
  src: url($font-path+'flama/Flama-Medium.woff2') format('woff2'),
    url($font-path+'flama/Flama-Medium.woff') format('woff'),
    url($font-path+'flama/Flama-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Flama';
  src: url($font-path+'flama/Flama-Semibold.woff2') format('woff2'),
    url($font-path+'flama/Flama-Semibold.woff') format('woff'),
    url($font-path+'flama/Flama-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tiempos Headline';
  src: url($font-path+'tiempos/TiemposHeadline-Regular.woff2') format('woff2'),
    url($font-path+'tiempos/TiemposHeadline-Regular.woff') format('woff'),
    url($font-path+'tiempos/TiemposHeadline-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
