@import "~@/styles/variables";





























.banners {
  position: sticky;
  top: 0;
  z-index: $z-index-2;
}
