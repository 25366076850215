@import "~@/styles/variables";









































































































































.help-search-result::v-deep em {
  font-style: normal;
  font-weight: $f-weight-bold;
}
