@import "~@/styles/variables";












































.unresolved-count {
  position: absolute;
  bottom: 0;
  left: 50%;
  border: 2px solid $pp-cream; // Matches the app background
}

.alerts:hover .bell-icon g {
  fill: $pp-blue-highlight;
}

.active .bell-icon g {
  stroke-width: 2px;
}
