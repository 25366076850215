@import "~@/styles/variables";




























































.med-image {
  align-self: center;
}
