@import "~@/styles/variables";











































.initials {
  font-size: 1.5em;
  font-weight: $f-weight-book;
  width: 2em;
  height: 2em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: $pp-primary-blue;
  color: $pp-white;
  border-radius: 50%;
  flex-shrink: 0;
}
