body.app-failure {
  #app {
    display: none;
  }
  #app-failure {
    display: block;
  }
}

body.app-maintenance {
  #app {
    display: none;
  }
  #app-maintenance {
    display: block;
  }
}

body.unsupported-browser {
  #app {
    display: none;
  }
  #app-unsupported-browser {
    display: block;
  }
}

#app-slow-to-load {
  display: block;
  position: absolute;
  background: $body-bg;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fallback-page {
  .other-options {
    font-size: 1.125rem;
  }

  .logo-img {
    width: 100px;
  }

  .icon-wrapper {
    flex-shrink: 0;
    position: relative;
    width: 64px;
    height: 64px;
    background: $pp-white;
    border-radius: 50%;

    &.icon-wrapper-small {
      height: 40px;
      width: 40px;

      .icon {
        height: 25px;
      }
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%)
    }
  }
}
