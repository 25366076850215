@import "~@/styles/variables";































.overlay {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: rgba(255, 255, 255, 0.6);
  justify-content: center;
  align-items: center;

  &.fullscreen {
    position: fixed;
  }

  &.opaque {
    background: white;
  }

  .spinner-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
