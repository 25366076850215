@import "~@/styles/variables";














































































.popover-btn {
  font-size: $f-size-sm;
}
