@import "~@/styles/variables";






























































































































































































.top-nav {
  transition: all 0.25s;

  &.fixed-top {
    background: $pp-white;
    box-shadow: $shadow-2;
  }
}

.nav-header {
  min-height: 60px;
  padding: 5px 0;
}

.mobile-menu {
  border-top: 1px solid transparent;
}

.navbar-brand {
  margin-left: 12px;
}

@media screen and (max-width: $mq-md - 1) {
  .top-nav {
    background: $pp-white;
    box-shadow: $shadow-2;

    .mobile-menu {
      border-top: 1px solid $pp-border-blue;
    }

    &.collapsed {
      transform: translateY(-60px); // height of nav-header section
    }
  }
}

.popover-btn {
  font-size: $f-size-sm;
}
