@import "~@/styles/variables";























































































































































.timer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transform-origin: left;
  transform: scaleX(0);
  background: $pp-white;
}
