@import "~@/styles/variables";





































































































































.non-packet-header {
  padding: 40px 20px 0 !important;
}

.nav-item.todays-meds-tab {
  margin-bottom: 0.5em;
  margin-right: 0.5em;

  .nav-link {
    display: flex;
    align-items: center;
    padding: 0.5em;
    border: 1px solid $pp-border-blue;
    border-radius: 8px;
    font-size: rem(18px);
    min-width: 7.3em; // makes all tabs the same width
    color: $body-color;

    .todays-med-tab-icon {
      width: 30px;
      height: 30px;
    }

    &:hover,
    &:focus {
      color: $pp-primary-blue;
      background: $pp-snowball;
    }

    &.active {
      border-color: $pp-primary-blue;
      color: $pp-primary-blue;
      background: transparent;
    }

    &:focus {
      box-shadow: $input-btn-focus-box-shadow;
    }

    @media (max-width: map-get($grid-breakpoints, 'sm')) {
      min-width: 5.3em; // makes all tabs the same width on mobile
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
