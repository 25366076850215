@import "~@/styles/variables";























































































.app-footer {
  color: rgba($pp-white, 0.8);
  padding-bottom: 4rem;
  background-image: linear-gradient(to bottom, fade-in($clear-black, 0.2), $clear-black 20px);
}

.mobile-link {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  color: inherit;
  border-bottom: 1px solid scale-color($pp-ocean, $lightness: 10%);
  padding: 1rem;
  cursor: pointer;

  &:hover,
  &.router-link-active {
    color: $pp-white;
    text-decoration: none;
  }
}

a.footer-link {
  color: inherit;
  text-decoration: underline;
  + a {
    margin-left: 1.5em;
  }

  &:hover {
    color: $pp-white;
  }
}
