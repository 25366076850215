@import "~@/styles/variables";







































.message {
  font-size: $f-size-md;
  font-weight: $f-weight-book;
}
.bullet-list {
  font-size: 1.125rem;
}
.line {
  margin: 30px 0;
}
