@import "~@/styles/variables";











































.step-container {
  flex: 1 1 auto;
  color: $step-progress-inactive;

  &.past {
    opacity: 0.5;
  }

  &.current,
  &.past {
    color: $step-progress-active;
  }

  &.error {
    color: $danger;
  }
}
