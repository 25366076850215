@import "~@/styles/variables";






























































































































.service-alert-title {
  &.unresolved {
    font-weight: $f-weight-bold;
  }
}
