@import "~@/styles/variables";


















// Smaller italic title text for page headers
// could appear before the title, i.e. "~Your~ Medications"
.page-pretitle {
  display: block;
  font-weight: $f-weight-regular;
  font-style: italic;
  font-size: 0.4em;
  margin-bottom: 0.2em;
}
