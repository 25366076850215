@import "~@/styles/variables";















.step-edge {
  height: 0;
  border-top-width: 1px;
  border-top-style: dashed;
  border-top-color: $step-progress-inactive;
}

.active {
  color: $step-progress-active;
  border-color: $step-progress-active;
  border-top-style: solid;
}
