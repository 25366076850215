@keyframes shimmer {
  from {
      background-position-x: -200px;
  }

  to {
      background-position-x: calc(200px + 100%);
  }
}

.skeleton-loading {
  background: $skeleton-loading-grey;
  opacity: 0.15;
}

// This class needs to be added to the container of skeleton elements
// to give them the shimmer animation
//
// This adds an overlay with an animated background gradient so all of the
// children of this element will have the same animation, running in sync
.skeleton-container {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    z-index: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 80%);
    background-size: 30% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2s ease-in-out 0s infinite;
  }
}


