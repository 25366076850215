@import 'bootstrap-variables';
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/src/index.scss';

// Custom CSS
[aria-expanded='false'] .when-opened,
[aria-expanded='true'] .when-closed {
  display: none !important;
}

// Cancel button
.btn-cancel {
  @include button-outline-variant(
    $pp-border-blue,
    $pp-primary-blue,
    $pp-cancel-gray,
    $pp-cancel-gray
  );
  color: $pp-primary-blue !important;
}

// Using the 'expander` mod on the LinkArrowIcon rotates
// the arrow down when collapsed and up when expanded
[aria-expanded='false'] .link-arrow.expander {
  transform: rotate(90deg);
}
[aria-expanded='true'] .link-arrow.expander {
  transform: rotate(270deg);
}

.navbar-nav .nav-link.active {
  .link-inner {
    font-weight: $f-weight-medium;
    box-shadow: inset 0 -3px 0 -1px $pp-primary-blue;
  }
}

// Style overrides
// Makes the badge more circular for single numbers
.badge-pill {
  padding-left: 0.5em;
  padding-right: 0.5em;
  min-width: 1.7em;
  line-height: 1.2;
}

.list-group-item {
  position: relative;

  // This code adds a small line to hide part of the top border
  // This creates the effect of a partial horizontal separator
  // between list items
  &:not(.full-line):before {
    content: '';
    position: absolute;
    display: block;
    top: -1px;
    left: 0;
    z-index: 2;
    height: 1px;
    width: $list-group-item-padding-x;
    background-color: $list-group-bg;
  }

  h5 {
    margin-bottom: 0.2em;
  }

  .link-arrow {
    margin-right: -2px;
  }

  // fix flush list groups nested in list group items
  .list-group-flush {
    margin: 0 -#{$list-group-item-padding-x};
  }
}

.list-group-flush {
  &.border-bottom-only {
    .list-group-item {
      margin-bottom: 0;
      border-top: none;
      border-bottom: $list-group-border-width solid $list-group-border-color;

      &:last-child {
        border-bottom: $list-group-border-width solid $list-group-border-color;
      }
    }
  }
}

.card-header + .list-group .list-group-item:first-child::before {
  display: none;
}

.card {
  box-shadow: $box-shadow-sm;
  margin-bottom: 1.5em;
  overflow: hidden;

  &:not([class*='border-']) {
    border-width: 0;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    .card-flush#{$infix} {
      margin-left: -$grid-gutter-width / 2;
      margin-right: -$grid-gutter-width / 2;
      border-radius: 0;
    }
  }
}

.close {
  opacity: 0.7;

  &:not(:disabled):not(.disabled):hover {
    opacity: 1;
  }
}

.card-header {
  background: transparent;
  border-bottom: none;
  position: relative;

  .card-title {
    margin-bottom: 0;
  }

  .card-subtitle {
    margin-top: 0.75em;
  }
}

.card-subtitle {
  font-weight: $f-weight-regular;
}

.modal-header {
  padding: 20px 14px 10px;
}

.modal-body {
  position: static;
}

.modal-footer {
  padding: 20px 14px 20px;
}

.modal-content {
  padding: 10px;
}

.form-group {
  margin-bottom: 1.5 * $spacer;

  &:last-child {
    margin-bottom: 0;
  }
}

.form-group label,
.form-group legend,
.form-label,
h6:not(.card-subtitle) {
  text-transform: uppercase;
  font-weight: $f-weight-medium;
  font-size: 0.8em;
  letter-spacing: 1px;
}

.form-text {
  margin-bottom: 1.5em;
}

.custom-checkbox {
  margin-bottom: -0.4em;
}

input[type='checkbox'] + label,
input[type='radio'] + label {
  text-transform: none;
  letter-spacing: 0;
  line-height: 1.6em;
  font-size: 0.9em;
  font-weight: $f-weight-regular;
}

.form-control {
  padding-bottom: $input-btn-padding-y + 0.1;

  &.focus,
  &:focus-within {
    // Identical to bootstrap focus styles
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: $input-box-shadow, $input-focus-box-shadow;
    } @else {
      box-shadow: $input-focus-box-shadow;
    }
  }
}

// ensure that the aside slot of b-media is a consistent width
.media {
  > :first-child:not(.media-body) {
    width: 40px;
    justify-content: center;
  }
  .media-body {
    overflow-x: hidden;
  }
}

.media-body p:last-child {
  margin-bottom: 0;
}

// our buttons are bigger than bootstraps, except the link variant
.btn-link {
  font-size: 1rem;
  padding: $btn-link-padding-y $btn-link-padding-x;
}

// We have a special hover/focus color for primary buttons
.btn-primary:not(.disabled):not(:disabled):hover,
.btn-primary:not(.disabled):not(:disabled):focus {
  background: $pp-primary-blue-hover;
}

// We have a special hover/focus color for primary links
a.text-primary:hover {
  color: $pp-primary-blue-hover !important;
}

small,
.small {
  font-weight: $f-weight-book;
}

.progress-bar {
  border-radius: $border-radius;
}

// custom switch

.custom-switch {
  .custom-control-label {
    padding-left: 0.5rem;

    &:hover {
      cursor: pointer;
      user-select: none;
    }
    // bg
    &:before {
      height: calc(#{$custom-switch-indicator-size} + 4px);
      background: $pp-border-blue;
      border-color: $pp-border-blue;
    }
    // knob
    &:after {
      box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
      background: $pp-white;
    }
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(1.25rem);
  }
}
