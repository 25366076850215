// Single-rule classes
// Some rules may require !important, but use sparingly

// Before adding a utility class make sure an equivalent
// does not exist in Bootstraps utility library
// https://getbootstrap.com/docs/4.3/utilities/borders/

.tiempos {
  font-family: $tiempos;
}

.flama {
  font-family: $flama;
}

.border-light-blue {
  border-color: $pp-border-blue;
}

// BROWSER HACK!
// This class is applied to any interactive element on mobile screens to show that it has been touched and will respond
// It subtly changes the opacity of the element to cause it to repaint and apply CSS styles for :active and :hover.
.pillpack--touch-active:not(:disabled) {
  opacity: .99;
}

.faded {
  opacity: 0.75;
}

$list-left-padding: 1.1225em;

ul.bullet-list {
  padding-left: $list-left-padding;

  > li {
    list-style: none;

    &::before {
      content: "\2022";  // Add content: \2022 is the CSS Code/unicode for a bullet
      color: $pp-primary-blue;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
}

select.plain-pp-select {
  width: auto;
  font-size: $f-size-md;
  color: $pp-primary-blue;
  background-color: transparent;
  border: none;
}

.search-input {
  font-size: 18px;
  padding-left: 2.5em; // room for search icon
  padding-right: 2em; // room for clear button
  background-image: $pp-search-icon-uri;
  background-repeat: no-repeat;
  background-position: 0.75em 50%;
}

.pointer-events-none {
  pointer-events: none;
}

.text-decoration-underline {
  text-decoration: underline;
}
