@import "~@/styles/variables";

























































































































$loading-offset: 20px; // to account for border-radius

.loading-container {
  position: absolute;
  top: $loading-offset;
  right: 0;
  bottom: $loading-offset;
  left: 0;
  overflow: hidden;
}

::v-deep {
  .modal-header {
    .modal-title {
      flex-grow: 1;
    }
    .close {
      float: none;
    }
  }
  .modal__confirming-close {
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: white;
      opacity: 0.9;
      z-index: $z-index-1;
    }
  }
  .modal__close-confirmation-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 8px;
    z-index: $z-index-2;

    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
  }
  .modal__close-confirmation-message {
    color: $txt-dark;
    font-size: $f-size-md;
  }
  .back-link-container {
    a {
      position: absolute;
      top: 30px;
    }
  }
}
