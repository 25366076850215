@import "~@/styles/variables";











































.blue-stroke g {
  stroke: $pp-primary-blue !important;
  stroke-width: 3px;
}

.grey-stroke g {
  stroke: $pp-dark-grey !important;
  stroke-width: 3px;
}

.blue-fill g {
  fill: $pp-primary-blue !important;
}

.grey-fill g {
  fill: $pp-dark-grey !important;
}
