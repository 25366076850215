@import "~@/styles/variables";




















































































































.onboarding-img {
  max-width: 90px;
  max-height: 100px;
}
.onboarding-text {
  max-width: 13em;
}
.onboarding-button {
  align-content: center;
  max-width: 12em;
}
