@import "~@/styles/variables";

































// Controls the font-size which drives all em values
$size: 1em;
$size-lg: 1.5em;

$blue-color: $pp-primary-blue;

.link-arrow {
  flex: 0 0 1em;
  vertical-align: middle;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: $size;
  width: 1em;
  height: 1em;
  line-height: 1em;
  padding-right: 0.1em;
  opacity: 0.75;

  &.large {
    font-size: $size-lg;
  }
  &.blue {
    color: $blue-color;
  }
  &.grey {
    color: $pp-md-grey;
  }
  &.left {
    transform: rotate(180deg);
  }
  &.down {
    transform: rotate(90deg);
  }
  &.up {
    transform: rotate(-90deg);
  }
  &.expander {
    transition: transform 0.25s;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    transform: rotate(45deg);
    border-top: 2px solid currentColor;
    border-right: 2px solid currentColor;
  }
}
