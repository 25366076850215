// Global settings for typographic display

h1,
h2,
h3 {
  font-family: $tiempos;
}

h5, .h5 {
  font-size: 1.0625em;
}

.subtitle {
  font-size: 0.94em;
}

.font-weight-medium {
  font-weight: $f-weight-medium !important;
}

button:focus, button.btn-link:focus {
  outline: 0;
  box-shadow: inset $input-focus-box-shadow;
}

.pillpack--tabfocus {
  a:focus {
    outline: 0;
    box-shadow: inset $input-focus-box-shadow;
  }
}
