@import "~@/styles/variables";




































































































































































































.help-header {
  height: 6em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0;

  > svg path {
    stroke: $pp-primary-blue;
  }
}

.help-card {
  &:hover {
    background: $list-group-hover-bg;
  }
}

.stroke-blue {
  & path {
    stroke: $pp-primary-blue;
  }

  & g {
    stroke: $pp-primary-blue;
  }
}
