@import "~@/styles/variables";

































.step-container {
  flex: 1 1 auto;
  justify-content: center;
  color: $step-progress-inactive;

  &.current {
    color: $step-progress-active;
  }
}
