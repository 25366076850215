<template>
  <div :style="{ height, transition }" class="logo-wrapper">
    <transition name="fade-up">
      <img
        key="horizontal-logo"
        v-if="horizontal"
        :alt="$t('PillPack: an Amazon company logo')"
        class="logo-img"
        src="@/assets/pp-az-logo-horizontal.png"
      />
      <img
        key="square-logo"
        v-else
        :alt="$t('PillPack: an Amazon company logo')"
        class="logo-img"
        src="@/assets/pp-az-logo.png"
      />
    </transition>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class SiteLogo extends Vue {
  @Prop(Boolean)
  horizontal!: boolean

  get height() {
    return this.horizontal ? '30px' : '84px'
  }

  get transition() {
    return this.horizontal ? 'height 0.25s' : 'none'
  }
}
</script>

<style scoped>
.logo-wrapper {
  position: relative;
  width: 100px;
}

.logo-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
}
</style>
