@import "~@/styles/variables";






















$offset: 8px;

.order-delivery-problem-alert {
  svg {
    width: 20px;
    margin: 6px $offset 0 -#{$offset};
    flex-shrink: 0;
  }
}
