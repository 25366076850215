@import "~@/styles/variables";












.hamburger-icon {
  vertical-align: middle;
  display: inline-block;
  width: 16px;
  height: 16px;

  &:before {
    vertical-align: top;
    content: '';
    display: inline-block;
    margin-top: 2px;
    width: 100%;
    height: 1px;
    background: currentColor;
    box-shadow: 0 5px 0 currentColor, 0 10px 0 currentColor;
  }
}
