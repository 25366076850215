@import "~@/styles/variables";


















































.loading-spinner {
  position: relative;
  display: flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  svg {
    width: 100%;
    height: 100%;
    animation: progress-circular-rotate 1.4s linear infinite;
    transform-origin: center center;
    transition: all 0.2s ease-in-out;

    circle {
      stroke: currentColor;
      animation: progress-circular-dash 1.4s ease-in-out infinite;
      stroke-linecap: round;
      stroke-dasharray: 80, 200;
      stroke-dashoffset: 0px;
      z-index: 2;
    }
  }

  &.button {
    position: absolute;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      width: 80% !important;
      height: 80% !important;
    }

    circle {
      stroke-width: 6;
    }
  }
}
@keyframes progress-circular-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -125px;
  }
}
@keyframes progress-circular-rotate {
  100% {
    transform: rotate(360deg);
  }
}
