@import "~@/styles/variables";
























































































.step-node {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  line-height: 22px;
  border: 1px solid $pp-blue;
  background: $step-progress-active;
}

.body {
  font-size: 1.1rem;
  width: fit-content;
}

.btn-link {
  margin-left: -0.5em;
  font-size: 1.2rem;
  font-family: $font-family-base;
  font-style: bold;
}
